import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../components/UX/Logo"
import { RevealTitle } from "../components/UX/VisibilitySensor"
import Section from "../components/UX/Section"
import { DownloadButton } from "../components/UI/Buttons"
import { TeamMember } from "../components/UX/team-panel"
import { GatsbyImage } from "gatsby-plugin-image"

const StrategyPage = ({ data }) => {
  const { title, statement, strategies } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const Strategy = strategies.map(strategy => {
    const { title, factsheet, content, team, image } = strategy
    let slug = title.replace(/\s+/g, "-").toLowerCase()
    return (
      <Section key={title}>
        <div className="container" id={`${slug}`}>
          <div className="grid-2-columns">
            <div>
              <RevealTitle barStyle="left" directionUp={true}>
                <h1>{title}</h1>
              </RevealTitle>
              <div className="grid-2-rows">
                <div
                  dangerouslySetInnerHTML={{ __html: content }}
                  style={{ whiteSpace: "pre-wrap" }}
                />
                <GatsbyImage
                  image={image.childImageSharp.gatsbyImageData}
                  alt={title}
                  style={{
                    maxWidth: "100%",
                    width: "-webkit-fill-available",
                    margin: "16px 0",
                  }}
                />
                <DownloadButton href={factsheet.publicURL} class="blue right">
                  Download Factsheet
                </DownloadButton>
              </div>
            </div>
            <div className="team-column" style={{ paddingTop: "3em" }}>
              {/*  <h3>Portfolio Team</h3> */}
              {team && <TeamMember members={team} />}
            </div>
          </div>
        </div>
      </Section>
    )
  })

  return (
    <Layout>
      <Seo title="Strategies" />
      <Section>
        <div className="container grid-by-4">
          <Logo />
          <div className="grid-right">
            <RevealTitle barStyle="right" directionUp={false}>
              <h1 style={{ textAlign: "left" }}>{title}</h1>
              <br></br>
              <p
                style={{
                  gridRowStart: "2",
                  gridColumnStart: "1",
                  alignItems: "end",
                }}
              >
                {statement}
              </p>
            </RevealTitle>
          </div>
        </div>
      </Section>
      {Strategy}
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(filter: { name: { eq: "strategies" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              statement
              strategies {
                content
                title
                team {
                  member
                }
                factsheet {
                  publicURL
                }
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default StrategyPage
