import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { PlusButton } from "../UI/Buttons"

const Panel = styled.div`
  width: fit-content;
  margin: 1em;

  h1 {
    white-space: pre-wrap;
    font-size: calc(0.7em + 1vw);
    font-weight: 600;
    text-align: left;
  }

  h3 {
    margin: 2px 0;
    font-weight: 400;
  }

  .image-box {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 15vw;
    min-height: 325px;
    margin: 0 50% 0 auto;
    padding: 10px;
    background: white;
    box-shadow: var(--cube-box-shadow);
  }

  .image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;
  }

  @media (max-width: 768px) {
    .image-box {
      width: 80%;
      min-height: unset;
      margin: 0;
    }
  }
`

export const TeamMember = ({ members }) => {
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: {
          childMarkdownRemark: { frontmatter: { cms: { eq: "team" } } }
        }
      ) {
        edges {
          node {
            childMarkdownRemark {
              fields {
                slug
              }
              frontmatter {
                name
                title
                image {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: BLURRED
                      formats: [PNG, WEBP, AVIF]
                      layout: CONSTRAINED
                      width: 300
                      height: 300
                      transformOptions: { cropFocus: CENTER }
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const list = data.allFile.edges

  const teams = []

  let names = members.map(n => n.member)

  list.map(entry => {
    if (names.indexOf(entry.node.childMarkdownRemark.frontmatter.name) > -1) {
      teams.push(entry)
    }
    return null
  })

  const TeamPanel = teams.reverse().map(member => {
    const slug = member.node.childMarkdownRemark.fields.slug
    const { name, title, image } = member.node.childMarkdownRemark.frontmatter

    return (
      <Panel key={name}>
        <div className="image-box">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
            style={{ maxWidth: "300px", width: "-webkit-fill-available" }}
          />
          <div className="flex-line">
            <>
              <div>
                <h1>{name}</h1>
                <h3>{title}</h3>
              </div>
              <PlusButton slug={slug} />
            </>
          </div>
        </div>
      </Panel>
    )
  })

  return <>{TeamPanel}</>
}
